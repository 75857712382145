import { httpOrigin } from '../../../configs/apiOrigin';
import logger from '../../../utils/logger';

function repository() {
  const origin = httpOrigin('/trader-rest-service/authn-method-check');
  let httpCode = 200;
  const response = {
    authnVer: 'v1',
    isInviteAccepted: false,
  };

  const apiAuthnMethodVerRequest = async ({ username = '' } = {}) => {
    try {
      const apiResponse = await fetch(`${origin}/${username}`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain',
        },
      });

      httpCode = apiResponse.status;
      if (!apiResponse.ok && httpCode !== 200 && httpCode !== 409) {
        throw new Error('Authentication method check failed');
      }
      const resTxt = await apiResponse.text();
      response.authnVer = resTxt;
      sessionStorage.setItem('authn_method_ver', resTxt);

      if (resTxt === 'v2' && httpCode === 409) {
        response.isInviteAccepted = false;
        return response;
      }
      if (resTxt === 'v2') response.isInviteAccepted = true;
      return response;
    } catch (err) {
      logger.logCriticalError(err);
      // do not throw (declare default as v1)
      sessionStorage.setItem('authn_method_ver', 'v1');
      response.authnVer = 'v1';
      response.isInviteAccepted = false;
      return response;
    }
  };

  return Object.freeze({
    apiAuthnMethodVerRequest,
  });
}

export default repository;
